<div class="flex gap-2 filters">
    <div *ngFor="let input of generateFields; let i = index">
        <forms-input-select
            *ngIf="input.type === 'option'"
            [placeholder]="input.placeholder"
            [options]="input.options"
            [form]="input.form"
            (changeForm)="change(i)"
            (changelabelForm)="sendChangelabelForm($event)"
            (valueChanged)="
                selectedCollectionPlace(i, $event, input.placeholder)
            "
        ></forms-input-select>

        <forms-input-text
            class="w-full"
            *ngIf="input.type === 'text'"
            type="text"
            [placeholder]="input.placeholder"
            [form]="input.form"
            [mask]="mask"
            (keyup.enter)="input.click && search(input)"
        ></forms-input-text>
    </div>
</div>
