import { Pipe, PipeTransform, inject } from "@angular/core";
import { LocaleDatePipe } from "./localeDate.pipe";
import { SpanPipe } from "./span.pipe";

/**
 * Pipes disponíveis para uso
 */
export type DynamicPipeValues = "localeDate" | "span" | "innerHtml";

/**
 * Pipe tem a função de abstrair chamadas para outros pipes. Tem o foco de ser usado apenas em Table Component
 */
@Pipe({
   name: "dynamicPipe",
   standalone: true
})
export class DynamicPipe implements PipeTransform {
   private _localeDatePipe = inject(LocaleDatePipe);
   private _spanPipe = inject(SpanPipe);

   /**
    *
    * @param value Valor recebido por pipe
    * @param pipeValues Object{name: Nome de pipe, args: argumentos que pipe utiliza}
    * @returns retorno o valor recebido transformado pelo pipe seleciodo
    */
   transform(
      value: any,
      pipeValues: { name: DynamicPipeValues; args?: any[] }
   ) {
      const args = pipeValues.args ? pipeValues.args : [];

      switch (pipeValues.name) {
         case "localeDate":
            return this._localeDatePipe.transform(value, ...args);
         case "span": {
            if (args[0] && value?.toString() in args[0]) {
               return this._spanPipe.transform(value, args[0]);
            } else {
               console.warn(`Valor "${value?.toString()}" não encontrado nos argumentos "${JSON.stringify(args[0])}".`);
               return "";
            }
         }
         default:
            console.error("Pipe informado está inválido: " + 1);
            return value;
      }
   }
}
