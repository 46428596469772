import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LoginComponent } from "@app/login/login.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { TypesPaymentComponent } from "./components/financial/types-payment/types-payment.component";
import { SipniComponent } from "./components/vaccines/sipni/sipni.component";
import { UnitMeasureComponent } from "./components/stock/unit-measure/unit-measure.component";
import { GroupProductComponent } from "./components/stock/group-product/group-product.component";
import { SchemmaComponent } from "./components/vaccines/schemma/schemma.component";
import { ApplicationOfVaccinesComponent } from "./components/vaccines/application-vaccines/application-of-vaccines.component";
import { VaccinesAdministrationComponent } from "./components/vaccines/vaccines-administration/vaccines-administration.component";
import { ProductComponent } from "./components/stock/product/product.component";
import { VaccineComponent } from "./components/vaccines/vaccine/vaccine.component";
import { EquipmentsComponent } from "@app/modules/interfacing/components/equipments/equipments.component";
import { SaveEquipmentComponent } from "@app/modules/interfacing/components/equipments/save-equipment/save-equipment.component";
import { TypeDocumentListComponent } from "./components/financial/type-document/type-document-list/type-document-list.component";
import { TypeIncomeExpenseListComponent } from "./components/financial/types-income-expense/type-income-expense-list/type-income-expense-list.component";
import { BearerListComponent } from "./components/financial/bearer/bearer-list/bearer-list.component";
import { OccurencyTypesComponent } from "./components/occurencyTypes/occurencyTypes.component";

const routes: Routes = [
   {
      path: "CadastrosOld",
      children: [
         { path: "", redirectTo: "Dashboard", pathMatch: "full" },
         { path: "Dashboard", component: DashboardComponent },
         {
            path: "Financeiro/Tipo-Documento",
            component: TypeDocumentListComponent
         },
         {
            path: "Concent-Apoio/Gerenciador-Mensagens",
            component: MessagesComponent
         },
         { path: "TiposPagamento", component: TypesPaymentComponent },
         { path: "Financeiro/Portadores", component: BearerListComponent },
         {
            path: "Financeiro/Tipos-Receita-Despesa",
            component: TypeIncomeExpenseListComponent
         },
         { path: "Vacinas/Sipni", component: SipniComponent },
         {
            path: "Vacinas/AdmVacinas",
            component: VaccinesAdministrationComponent
         },
         { path: "Vacinas/EsquemaVacinacao", component: SchemmaComponent },
         { path: "Exames/UnidadeDeMedida", component: UnitMeasureComponent },
         { path: "Estoque/UnidadeDeMedida", component: UnitMeasureComponent },
         { path: "Estoque/GrupoDeEstoque", component: GroupProductComponent },
         {
            path: "Vacinas/AplicacaoVacina",
            component: ApplicationOfVaccinesComponent
         },
         { path: "Vacinas/Vacina", component: VaccineComponent },
         { path: "Estoque/Produto", component: ProductComponent },
         {
            path: "Interfaceamento/Equipamentos",
            component: EquipmentsComponent
         },
         {
            path: "Interfaceamento/Equipamentos/:id",
            component: SaveEquipmentComponent
         },
         {
            path: "Atendimento/Tipos-Ocorrencias",
            component: OccurencyTypesComponent
         }
      ]
   },
   {
      path: "Login",
      children: [
         { path: "", redirectTo: "Login", pathMatch: "full" },
         { path: "Login", component: LoginComponent }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class RegistersRoutingModule {}
