import { RegistersRoutingModule } from "./modules/registers/registers-routing.module";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { QueriesRoutingModule } from "./modules/queries/queries-routing.module";
import { MovimentationRoutingModule } from "./modules/movimentation/movimentation-routing.module";
import { ParametersRoutingModule } from "./modules/parameters/parameters-rounting.module";
import { InterfacingRoutingModule } from "./modules/interfacing/interfacing-rounting.module";
import { RedirectComponent } from "./redirect/redirect.component";
import { LoginComponent } from "./modules_new/external/components/form/login/login.component";
import { MenuItemComponent } from "./modules_new/admin/pages/menuItem/menuItem.component";
import { IpAddressesReleasedComponent } from "./modules_new/administrativeData/pages/ip-addresses-released/ip-addresses-released.component";
import { UserAuthenticateComponent } from "./modules_new/administrativeData/pages/userAuthenticate/userAuthenticate.component";
import { UsersComponent } from "./modules_new/administrativeData/pages/users/users.component";
import { UsersNewComponent } from "./modules_new/administrativeData/components/form/users-new/users-new.component";
import { GroupsComponent } from "./modules_new/administrativeData/pages/groups/groups.component";
import { DepartmentsComponent } from "./modules_new/administrativeData/pages/departments/departments.component";
import { MyProfileComponent } from "./modules_new/administrativeData/pages/myProfile/myProfile.component";
import { EmptyPageComponent } from "./modules_new/shared/components/emptyPage/emptyPage.component";

const routes: Routes = [
   {
      path: "",
      component: MainMenuComponent
   },
   {
      path: "redirect",
      component: RedirectComponent
   },
   {
      path: "CadastrosOld",
      loadChildren: () =>
         import("./modules/registers/registers.module").then(
            (m) => RegistersRoutingModule
         )
   },
   {
      path: "Consultas",
      loadChildren: () =>
         import("./modules/queries/queries.module").then(
            (m) => QueriesRoutingModule
         )
   },
   {
      path: "MovimentacaoOld",
      loadChildren: () =>
         import("./modules/movimentation/movimentation.module").then(
            (m) => MovimentationRoutingModule
         )
   },
   {
      path: "Interfaceamento",
      loadChildren: () =>
         import("./modules/interfacing/interfacing.module").then(
            (m) => InterfacingRoutingModule
         )
   },
   {
      path: "Parameters",
      loadChildren: () =>
         import("./modules/parameters/parameters-rounting.module").then(
            (m) => ParametersRoutingModule
         )
   },
   {
      path: "Parametros",
      loadChildren: () =>
         import("./modules_new/parameters/parameters-routing").then(
            (m) => m.PARAMETERS_ROUTER
         )
   },
   {
      path: "Admin",
      loadChildren: () =>
         import("./modules_new/admin/route.routing").then(
            (m) => m.ACCOUNT_ROUTER
         )
   },
   {
      path: "Cadastros",
      loadChildren: () =>
         import("./modules_new/registers/registers-routing").then(
            (m) => m.REGISTER_ROUTER
         )
   },
   {
      path: "Movimentacao",
      loadChildren: () =>
         import("./modules_new/movimentation/movimentation-routing").then(
            (m) => m.MOVIMENTATION_ROUTER
         )
   },
   {
      path: "Relatorios",
      loadChildren: () =>
         import("./modules_new/reports/reports-routing").then(
            (m) => m.REPORTS_ROUTER
         )
   },
   {
      path: "EsqueciMinhaSenha",
      component: ForgotPasswordComponent
   },
   {
      path: "AdminOld",
      loadChildren: () =>
         import("./modules/admin/admin.module").then((m) => m.AdminModule)
   },
   {
      path: "Externo",
      loadChildren: () =>
         import("./modules_new/external/external-routing").then(
            (m) => m.EXTERNAL_ROUTER
         )
   },
   {
      path: "Acessos",
      component: UserAuthenticateComponent
   },
   {
      path: "Usuarios",
      component: UsersComponent
   },
   {
      path: "Usuarios/Novo",
      component: UsersNewComponent
   },
   {
      path: "Usuarios/Editar/:id",
      component: UsersNewComponent
   },
   // {
   //    path: "Estatistica",
   //    loadChildren: () =>
   //       import("./modules/traceability/traceability.module").then(
   //          (m) => TraceabilityModule
   //       )
   // },
   {
      path: "Estatistica",
      loadChildren: () =>
         import("./modules_new/traceability/traceability-routing").then(
            (m) => m.TRACEABILITY_ROUTER
         )
   },
   {
      path: "Empresas",
      loadChildren: () =>
         import(
            "./modules_new/administrativeData/pages/company_new/company_routing"
         ).then((m) => m.COMPANY_ROUTER)
   },
   {
      path: "Grupos",
      component: GroupsComponent
   },
   {
      path: "resultados",
      component: LoginComponent
   },
   {
      path: "Departamentos",
      component: DepartmentsComponent
   },
   {
      path: "Controle_de_Acesso",
      component: IpAddressesReleasedComponent
   },
   {
      path: "Menu",
      component: MenuItemComponent
   },
   {
      path: "Conta/MeuPerfil",
      component: MyProfileComponent
   },
   { path: "**", component: EmptyPageComponent } //Esta linha deve permanecer no final do objeto para que todas as rotas sejam reconhecidas corretamente
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         preloadingStrategy: PreloadAllModules
      })
   ],
   exports: [RouterModule]
})
export class AppRoutingModule {}
