import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { InputSelectFormComponent } from "../input-select/base/input-select.component";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { ErrorTranslatePipe } from "@app/modules_new/shared/pipe/error-translate.pipe";

@Component({
   selector: "form-select-prop",
   templateUrl: "./select-prop.component.html",
   styleUrls: ["./select-prop.component.scss"],
   standalone: true,
   imports: [
      CommonModule,
      TranslateModule,
      ReactiveFormsModule,
      MatSelectModule,
      ErrorTranslatePipe
   ]
})
export class FormSelectPropComponent implements OnInit {
   @Input() form!: FormControl;
   @Input() placeholder: string = "";
   @Input() allowToEraseValue = false;
   @Input() options: Array<{ value: string; label: string }> = [];
   @Output() valueChanged = new EventEmitter<Object>

   constructor() {}

   ngOnInit(): void {
      this.form.valueChanges.subscribe(value => {
         this.valueChanged.emit(this.options.find(option => option.value == value))
      })
   }

   onUserChange(event: MatSelectChange) {
      
   }
}
