import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwalStatics, SwalTranslateDefaults, closeSwalFn } from './toast.types';

/**
 * Service responsável por exibir alertas/toasts/modais com textos traduzidos
 *
 * OBS: todos os parâmetros devem ser usados indicando as tags do json de tradução.
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(private _translateService: TranslateService){
  }

  /**
   * Gera `Modal` para confirmação do usuário.
   *
   * @param title titulo que será exibido
   * @param html html que será usado como corpo
   * @param confirmText texto em botão de confirmação
   * @returns Retorna promessa com resposta a interação do usuário
   * @type {Promisse<{"isConfirmed": boolean, "isDenied": boolean, "isDismissed": boolean, "value": boolean }>}
   */
  public async fireConfirmationHtml(title: string, html: string, confirmText: string) {
    const responses = await this._translateService.get([title, html, confirmText]).toPromise();
    return SwalStatics.ALERT_GENERIC.fire({
      title: responses[title],
      html: responses[html],
      confirmButtonText: responses[confirmText],
    });
  }

   /**
   * Gera `Modal` para confirmação do usuário.
   *
   * @param title titulo que será exibido
   * @param message texto no corpo da modal
   * @returns Retorna promessa com resposta a interação do usuário
   * @type {Promisse<{"isConfirmed": boolean, "isDenied": boolean, "isDismissed": boolean, "value": boolean }>}
   */
   public async fireConfirmation(title: string = SwalTranslateDefaults.CONFIRMATION_QUESTION, message?: string) {
    const responses = await this._translateService.get([title, SwalTranslateDefaults.YES, SwalTranslateDefaults.NO]).toPromise();
    return SwalStatics.ALERT_CONFIRMATION(
      responses[title],
      responses[SwalTranslateDefaults.YES],
      responses[SwalTranslateDefaults.NO],
      message
    ).fire();
  }

  /**
   * Gera `modal` para usuário confirmar deleção de algo
   *
   * @returns Retorna promessa com resposta a interação do usuário
   * @type {Promisse<{"isConfirmed": boolean, "isDenied": boolean, "isDismissed": boolean, "value": boolean }>}
   */
  public async fireConfirmDeletion() {
    const responses = await this._translateService
      .get([
        SwalTranslateDefaults.CONFIRMATION_QUESTION,
        SwalTranslateDefaults.IRREVERSIBLE_ALERT,
        SwalTranslateDefaults.BTN_CONFIRM,
        SwalTranslateDefaults.BTN_CANCEL,
      ])
      .toPromise();
    return SwalStatics.ALERT_DELETE(
      responses[SwalTranslateDefaults.CONFIRMATION_QUESTION],
      responses[SwalTranslateDefaults.IRREVERSIBLE_ALERT],
      responses[SwalTranslateDefaults.BTN_CONFIRM],
      responses[SwalTranslateDefaults.BTN_CANCEL]
    ).fire();
  }

  /**
   * Gera `alert` em tela
   *
   * @param message informação que será exibida para usuário
   */
  public async fireAlertNavegator(message: string) {
    const responses = await this._translateService.get([SwalTranslateDefaults.IMPORTANT, message]).toPromise();
    SwalStatics.ALERT_NAV.fire({
      title: responses[SwalTranslateDefaults.IMPORTANT],
      text: responses[message],
      showCloseButton: true,
    }).finally(() => {
      document.body.removeEventListener('click', closeSwalFn);
    });
  }

  /**
   * Gera um `toast` de sucesso
   *
   * @param message informação que será exibida para usuário
   */
  public async fireSuccess(message: string) {
    const responses = await this._translateService.get([SwalTranslateDefaults.SUCCESS, message]).toPromise();
    SwalStatics.ALERT_SUCCESS(responses[SwalTranslateDefaults.SUCCESS]).fire({
      text: responses[message],
    });
  }

  /**
   * Gera um `toast` de Alerta importante!
   *
   * @param message informação que será exibida para usuário
   */
  public async warningMessage(message: string) {
    const responses = await this._translateService.get([SwalTranslateDefaults.IMPORTANT, message]).toPromise();
    SwalStatics.WARNING_INFO(responses[SwalTranslateDefaults.IMPORTANT]).fire({
      text: responses[message],
    });
  }

  /**
   * Gera um `toast` de error
   *
   * @param text informação que será exibida para usuário
   * @param title titulo que será exibido @default {SwalTranslateDefaults.ERROR}
   */
  public async fireError(text: string, title: string = SwalTranslateDefaults.ERROR) {
    const responses = await this._translateService.get([title, text]).toPromise();
    SwalStatics.ALERT_ERROR(responses[title]).fire({
      text: responses[text],
    });
  }

  /**
   * Gera um `toast` de error
   *
   * @param err Erro generico recebido de API
   */
  public async fireGenericError(err: ErrorEvent, title: string = SwalTranslateDefaults.ERROR) {
    const responses = await this._translateService
      .get([
        title,
        SwalTranslateDefaults.EXPIRED_ACCESS,
        SwalTranslateDefaults.EXPIRED_ACCESS_TEXT,
        SwalTranslateDefaults.INVALID_PERMISSION,
        SwalTranslateDefaults.INVALID_PERMISSION_TEXT,
      ])
      .toPromise();

    if (err.error) {
      if (err.error.status == 400 && err.error.title != 'One or more validation errors occurred.') {
        SwalStatics.ALERT_ERROR(responses[SwalTranslateDefaults.EXPIRED_ACCESS]).fire({
          text: responses[SwalTranslateDefaults.EXPIRED_ACCESS_TEXT],
        });

        setTimeout(() => {
          localStorage.setItem('token', '');
          window.location.href = '/';
        }, 3000);
      } else {
        if (err.error.status == 401 && err.error.title != 'One or more validation errors occurred.') {
          SwalStatics.ALERT_ERROR(responses[SwalTranslateDefaults.INVALID_PERMISSION]).fire({
            text: responses[SwalTranslateDefaults.INVALID_PERMISSION_TEXT],
          });
        } else {
          if (err.error.errors) {
            let html = '';
            if (Array.isArray(err.error.errors))
              err.error.errors.forEach((error: any, index: any) => {
                html += error + (index < err.error.errors.length - 1 ? '<br><br>' : '');
              });
            else {
              Object.keys(err.error.errors).forEach((error, index) => {
                html += err.error.errors[error] + (index < err.error.errors.length - 1 ? '<br>' : '');
              });
            }

            SwalStatics.ALERT_ERROR(responses[title]).fire({
              html
            });
          } else {
            SwalStatics.ALERT_ERROR(responses[title]).fire({
              html: 'Status:' + err.error.status + ' | ' + err.error.title,
            });
          }
        }
      }
    } else {
      let message = '';
      if ((err as any)['errors'])
        (err as any).errors.forEach((x: any) => {
          message += x + ' ';
        });

      SwalStatics.ALERT_ERROR(responses[title]).fire({
        text: message,
      });
    }
  }

  /**
   * Gera modal com loader
   */
  public async fireLoader() {
    const responses = await this._translateService.get([SwalTranslateDefaults.LOADING]).toPromise();
    SwalStatics.ALERT_LOADER(responses[SwalTranslateDefaults.LOADING]).fire();
  }
}
