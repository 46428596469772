<div class="container">
    <mat-select
        #selectRef
        class="padding"
        [formControl]="formValue"
        [disabled]="tabindexcomponent === '-1' || forcingDisable"
        (ngModelChange)="(formValue)"
        (openedChange)="onOpen($event)"
        [placeholder]="placeholder | translate"
        multiple
    >
        <forms-input-text
            [form]="formSearch"
            [placeholder]="'default.searchRegister' | translate"
            #focusableInputs
        ></forms-input-text>
        <mat-select-trigger>
            <div
                class="flex"
                style="
                    overflow-x: auto;
                    padding: 7px;
                    gap: 2px;
                "
            >
                <div *ngFor="let option of form.value">
                    <ng-container *ngIf="hasOptionStyle; else default">
                        <span [class]="optionStyle">
                            {{ option.label | translate }}
                        </span>
                    </ng-container>
                    <ng-template #default>
                        <strong class="font-semibold">{{
                            option.label
                        }}</strong
                        >,
                    </ng-template>
                </div>
            </div>
        </mat-select-trigger>
        <mat-option
            *ngFor="let option of options | searchFilter : formSearch.value"
            [value]="option.value"
            class="hover:bg-[#F5F5F5]"
            [disabled]="
                (optionAll &&
                    this.form.value.includes('todos') &&
                    option.value !== 'todos') ||
                option.disable
            "
            [ngClass]="
                (optionAll &&
                    this.form.value.includes('todos') &&
                    option.value !== 'todos') ||
                option.disable
                    ? 'opacity'
                    : ''
            "
        >
            <div class="flex align-center gap8">
                {{ option.label | translate }}
            </div>
        </mat-option>
    </mat-select>
</div>
