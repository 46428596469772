import { RegistersRoutingModule } from "./registers-routing.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentModule } from "@app/components/common-component.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { MessagesComponent } from "./components/messages/messages.component";
import { TypesPaymentComponent } from "./components/financial/types-payment/types-payment.component";
import { TitlesComponent } from "./components/financial/titles/titles.component";
import { SaveTitleComponent } from "./components/financial/titles/save-title/save-title.component";
import { SipniComponent } from "./components/vaccines/sipni/sipni.component";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { UnitMeasureComponent } from "./components/stock/unit-measure/unit-measure.component";
import { GroupProductComponent } from "./components/stock/group-product/group-product.component";
import { SchemmaComponent } from "./components/vaccines/schemma/schemma.component";
import { ApplicationOfVaccinesComponent } from "./components/vaccines/application-vaccines/application-of-vaccines.component";
import { VaccinesAdministrationComponent } from "./components/vaccines/vaccines-administration/vaccines-administration.component";
import { ProductComponent } from "./components/stock/product/product.component";
import { VaccineComponent } from "./components/vaccines/vaccine/vaccine.component";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { TypeDocumentListComponent } from "./components/financial/type-document/type-document-list/type-document-list.component";
import { TypeIncomeExpenseListComponent } from "./components/financial/types-income-expense/type-income-expense-list/type-income-expense-list.component";
import { BearerListComponent } from "./components/financial/bearer/bearer-list/bearer-list.component";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { OccurencyTypesComponent } from "./components/occurencyTypes/occurencyTypes.component";
@NgModule({
   imports: [
      CommonModule,
      CommonComponentModule,
      FormsModule,
      ReactiveFormsModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatTabsModule,
      MatCheckboxModule,
      MatStepperModule,
      MatIconModule,
      MatTooltipModule,
      MatDividerModule,
      MatProgressSpinnerModule
   ],
   declarations: [
      DashboardComponent,
      MessagesComponent,
      TypesPaymentComponent,
      TitlesComponent,
      SaveTitleComponent,
      SipniComponent,
      UnitMeasureComponent,
      VaccineComponent,
      GroupProductComponent,
      VaccinesAdministrationComponent,
      SchemmaComponent,
      ApplicationOfVaccinesComponent,
      ProductComponent,
      TypeDocumentListComponent,
      TypeIncomeExpenseListComponent,
      BearerListComponent,
      OccurencyTypesComponent
   ],
   exports: [RegistersRoutingModule],
   providers: [LocalaDatePipe, GetActiveFilterPipe, CheckboxSvgPipe]
})
export class RegistersModule {}
