import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MovimentationRoutingModule } from "./movimentation-routing.module";
import { CommonComponentModule } from "@app/components/common-component.module";
import { BrowserModule } from "@angular/platform-browser";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ProcessesComponent } from "./components/processes/processes.component";
import { TypingComponent } from "./components/typing/typing.component";
import { ConferenceComponent } from "./components/conference/conference.component";
import { LiberationComponent } from "./components/liberation/liberation.component";
import { PrintingComponent } from "./components/printing/printing.component";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
   imports: [
      BrowserModule,
      CommonModule,
      CommonComponentModule,
      MovimentationRoutingModule,
      MatIconModule,
      MatTooltipModule,
      MatTabsModule,
      MatDividerModule
   ],
   declarations: [
      ProcessesComponent,
      TypingComponent,
      ConferenceComponent,
      LiberationComponent,
      PrintingComponent
   ],
   exports: [MovimentationRoutingModule]
})
export class MovimentationModule {}
