import { Inject, Injectable } from "@angular/core";
import { Guid } from "../util/guid";
import { RequestOptions } from "@app/modules_new/data/types/http.types";
import { IBaseService } from "@app/modules_new/admin/data/services/interfaces/IBaseService";
import { IHttpService } from "@app/modules_new/admin/data/services/interfaces/IHttpService";

@Injectable({
   providedIn: "root"
})
export abstract class BaseNewService implements IBaseService {
   protected service!: IHttpService;
   protected path: string;

   //@ts-nocheck
   constructor(
      @Inject(String) httpService: IHttpService,
      @Inject(String) path: string
   ) {
      this.path = `api/${path}`;
      this.service = httpService;
   }

   get<T>(options?: RequestOptions) {
      return this.service.get<T>({
         ...options,
         path: this.path + (options?.path || "")
      });
   }

   getById<T>(id: Guid, options?: RequestOptions) {
      return this.service.get<T>({
         path: `${this.path}/${id}`,
         headers: options?.headers
      });
   }

   getPagination<T>(
      page: number,
      numberRegistry: number,
      options?: RequestOptions
   ) {
      const paginationQuery = {
         page,
         numberRegistry,
         ...options?.query
      };

      return this.service.get<T>({
         path: this.path + (options?.path || ""),
         query: paginationQuery,
         headers: options?.headers
      });
   }

   post<T>(body: object, options?: RequestOptions) {
      if (!body) throw new Error("Objeto inválido");
      return this.service.post<T>(body, {
         ...options,
         path: this.path + (options?.path || "")
      });
   }

   // postToPath(body: Object, path : string): Promise<Object> {
   //    if (!body) throw new Error('Objeto inválido');
   //    return this.service.post(body, `api/${this.path}/${path}`);
   //  }

   put<T>(body: object, id: Guid, options?: RequestOptions) {
      if (!body) throw new Error("Objeto inválido");
      return this.service.put<T>(body, id, {
         ...options,
         path: `${this.path + (options?.path || "")}/`
      });
   }

   delete<T>(id?: Guid, options?: RequestOptions & { body?: object }) {
      return this.service.delete<T>(id, {
         ...options,
         path: this.path + (options?.path || "")
      });
   }

   deleteWithBody<T>(id?: Guid, body?: object, options?: RequestOptions) {
      if (!body) throw new Error("Objeto inválido");
      return this.service.delete<T>(id, {
         body,
         ...options,
         path: this.path + (options?.path || "")
      });
   }

   patch(body: object, id: Guid, options?: RequestOptions) {
      if (!body) throw new Error("Objeto inválido");
      return this.service.patch(body, id, {
         ...options,
         path: this.path + (options?.path || "")
      });
   }

   patchToggleIsActive(
      id: string,
      newValue: boolean,
      options?: RequestOptions
   ) {
      const infosToPut = [
         {
            Opt: "replace",
            Patch: "/IsActive",
            Value: newValue.toString()
         }
      ];
      return this.service.patch(infosToPut, new Guid(id), {
         ...options,
         path: this.path + (options?.path || "")
      });
   }
}
