<div [ngClass]="removePadding ? '' : 'contentPage'" id="contentFilter">
    <div
        [ngStyle]="
            marginBottom !== null ? { 'margin-bottom': marginBottom } : {}
        "
        class="flex align-center flex-between gap-4 header"
        *ngIf="title != '' || createButton != undefined"
    >
        <div class="titulo w-full" id="titulo">
            <div class="flex align-center gp-12 w-full">
                <div
                    [class]="filtersRight ? ' ' : 'justify-between'"
                    class="flex align-center w-full header gap-2"
                >
                    <div class="flex justify-between align-center gap-2">
                        <h1 *ngIf="!h2" id="tit">
                            {{ title }}
                            <span
                                *ngIf="orangeTitle != ''"
                                class="orangeTitle"
                                >{{ orangeTitle }}</span
                            >
                        </h1>
                        <h2 *ngIf="h2" id="tit" class="text-xl font-bold">
                            {{ title }}
                        </h2>
                        <div *ngIf="!hideButton">
                            <a
                                *ngIf="createButton && !createButton.action"
                                class="noAdm"
                                [routerLink]="createButton.redirectPath"
                                [class]="
                                    reverseButton ? 'btn-reverse btn' : 'btn'
                                "
                                [class.isAdm]="isAdm"
                            >
                                {{ createButton.title }}
                            </a>
                            <a
                                id="btn"
                                class="noAdm"
                                [class.isAdm]="isAdm"
                                *ngIf="createButton && createButton.action"
                                (click)="onButtonClicked()"
                                [class]="
                                    reverseButton ? 'btn-reverse btn' : 'btn'
                                "
                            >
                                {{ createButton.title }}
                            </a>
                            <a
                                id="btn"
                                class="noAdm ml-2"
                                *ngIf="createSecondButton"
                                [class.isAdm]="isAdm"
                                (click)="onSecondButtonClicked()"
                                [class]="
                                    reverseButton ? 'btn-reverse btn' : 'btn'
                                "
                            >
                                {{ createSecondButton.title }}
                            </a>
                        </div>
                    </div>
                    <div
                        [class]="
                            filtersRight
                                ? 'flex gap-2 justify-end '
                                : 'flex gap-2 filters'
                        "
                        *ngIf="formFilterHeader"
                    >
                        <change-field
                            class="w-full"
                            [generateFields]="formFilterHeader"
                            (select1)="changeSelect1()"
                            (select2)="changeSelect2()"
                            (changelabelForm)="sendChangelabelForm($event)"
                            (searchText)="change($event)"
                        >
                        </change-field>
                    </div>
                    <div id="filter">
                        <filter-form
                            appCloseFilter
                            (clickOutside)="closeFilter()"
                            *ngIf="formFilter && showFilter"
                            [formFilter]="formFilter"
                            (clearFilters)="resetFilter()"
                            (searchFilters)="search()"
                            (clearFiltersPartial)="resetFiltersPartial()"
                        >
                        </filter-form>
                        <forms-input-filter
                            *ngIf="filterPlaceholder"
                            [placeholder]="filterPlaceholder"
                            [initialValue]="initialFilterValue"
                            [syncWithURLQuery]="initFilterFromURLQuery"
                            [isHeaderResponsive]="isResize"
                            (filter)="onFilter($event)"
                        ></forms-input-filter>
                    </div>
                    <a *ngIf="backPath != ''" class="btn" (click)="returnPage()"
                        >Voltar</a
                    >
                </div>
                <div></div>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</div>
