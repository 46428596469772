import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "Editar",
      svg: "action-write",
      event: "EDIT"
   }
];

export const tableDefinitions: ColumnDefinition[] = [
   {
      label: "customer.register.name",
      key: "name"
   },
   {
      label: "E-mail/Código de acesso",
      key: "accessCode"
   },
   {
      label: "Telefone",
      key: "cellPhone"
   },
   {
      label: "default.active",
      key: "isActive",
      component: "checkbox"
   }
];
